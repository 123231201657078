<template>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <Toast />
        <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
        <div class="flex flex-column align-items-center justify-content-center">
            
            <div style="
          border-radius: 56px;
          padding: 0.3rem;
          
        ">
                <div class="w-full surface-card py-7 px-5 sm:px-8 blue_shadow" style="border-radius: 53px">
                    <div class="text-center">

                        <div  style="color: #6b719b; font-size: 30px; font-weight: 800">Login</div>
                    </div>

                    <div>
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                        <InputText id="email1" type="text" placeholder="Email address" class="w-full md:w-30rem mb-5"
                            style="padding: 1rem" v-model="email" />

                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <!-- <Password aria-expanded="false" v-model="password" placeholder="Password"  class="w-full mb-3" inputClass="w-full" :inputStyle="{ padding: '1rem' }"></Password> -->
                        <InputText id="password1" v-model="password" type="password" class="w-full mb-3"
                            placeholder="Password" style="padding: 1rem" @keypress.enter="Login" />
                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <!-- <div class="flex align-items-center">
                                <Checkbox v-model="checked" id="rememberme1" binary class="mr-2"></Checkbox>
                                <label for="rememberme1">Remember me</label>
                            </div> -->
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer"  @click="delete_toggle(item)"
                                style="color: var(--primary-color)">Forgot password?</a>
                        </div>
                        <Button label="Sign In" class="w-full p-3 text-xl btn_light_blue" @click="Login"></Button>
                    </div>
                </div>
                <OverlayPanel ref="del" >
                    <div class="flex flex-column gap-3 w-25rem">
                      <h5>Change Password</h5>

                      <div class="col-12">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 md:col-12">
                                <label for="password">Mobile Number</label>
                                <InputText 
                                type="text" 
                                v-model="mobile" 
                                
                                />
                              </div>
                          <div class="field col-12 md:col-12">
                            <label for="password">New Password</label>
                            <InputText 
                            type="text" 
                            v-model="newpassword" 
                            
                            />
                          </div>

                          <div class="field col-12 md:col-4">
                            <Button label="Save" @click="Forgot_password"  class="p-button-primary btn_light_blue"/>
                          </div>
                          <div class="field col-12 md:col-4">
                            <Button label="Cancel" @click="Cancel_password"  class="p-button-danger btn_red"/>
                          </div>

                        </div>
                      </div>

                    </div>
                </OverlayPanel>
            </div>
        </div>
    </div>
   </template>
<script>
import apis from "@/apis";
export default {
    data() {
        return {
            isLoadingModel: false,
            email:"",
            password:"",
            newpassword:"",
            fullPage: true,
            email: "",
            password: "",
            checked: false,
            show: false,
            user_type: "",
            dropdownItems: [
                { name: "Admin", value: "admin" },
                { name: "Teacher", value: "teacher" },
                { name: "Assistent Teacher", value: "assteacher" },
                { name: "Student", value: "student" },
                { name: "Network Administrator", value: "network" },
            ],
        };
    },
    methods: {
        Forgot_password(){
            
        },
        Cancel_password(){
            this.newpassword = "";
        },
        delete_toggle() 
      { 
        
        this.$refs.del.toggle(event);
      },
        async Login() {
            if (
                this.email === "" ||
                this.email === null ||
                this.email === undefined
            ) {
                //alert("Please Enter Username");
                this.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Please Enter Username",
                    life: 3000,
                });
                return false;
            }
            if (
                this.password === "" ||
                this.password === null ||
                this.password === undefined
            ) {
                //alert("Please Enter Password");
                this.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Please Enter Password",
                    life: 3000,
                });
                return false;
            }
            var data = {
                email_id: this.email,
                password: this.password,
            };
            this.isLoadingModel = true;
            var promise = apis.login(data);
            promise
                .then((response) => {
                    this.isLoadingModel = false;
                    if (response.data.status == true) {
                        localStorage.clear();

                        
                        localStorage.name = response.data.data[0].name;
                        localStorage.email_id = response.data.data[0].email_id;
                        localStorage.mobile_number = response.data.data[0].mobile_number;
                        localStorage.profile_image = response.data.data[0].profile_image;
                        localStorage.email_notification = response.data.data[0].email_notification;


                        localStorage.user_type = response.data.data[0].user_type;
                        localStorage.id = response.data.data[0]._id;
                        localStorage.token = response.data.data[0].logintoken;
                        localStorage.client_id = response.data.data[0].client_id
                            ? response.data.data[0].client_id
                            : "";
                        //for fleet manager
                        localStorage.role_id = response.data.data[0].role_id[0].role_id;
                        localStorage.setItem(
                            "user_profile",
                            JSON.stringify(response.data.data[0])
                        );
                            var dashboard_link="";
                           if (response.data.data[0].role_id && response.data.data[0].role_id[0].dashboard_link)
                           {
                             dashboard_link=response.data.data[0].role_id[0].dashboard_link.toString();
                            localStorage.Breadcrumb_home = dashboard_link;  //for Breadcrumb_home
                            this.$router.push({name:dashboard_link});
                           }
                           else
                           {
                              dashboard_link = '/dashboard';                    //for Breadcrumb_home
                              localStorage.Breadcrumb_home = dashboard_link;    //for Breadcrumb_home
                            this.$router.push('/dashboard');
                           }
                        //this.$router.push("/dashboard");
                       
                        
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error Message",
                            detail: response.data.message,
                            life: 3000,
                        });
                        return false;
                    }
                })
                .catch((error) => {
                    this.isLoadingModel = false;
                    this.$toast.add({
                        severity: "error",
                        summary: "Error Message",
                        detail: "Invalid Login Credentials",
                        life: 3000,
                    });
                    return false;
                });
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return "white";
            return "dark";
        },
    },
};
</script>
<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}

#logo {
    margin-bottom: 1rem !important;
    width: 15%;
}

.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>