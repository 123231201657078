<template>
    <div>
        <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #F4F6F9;">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
        <div id="card1" class="card d-flex justify-content-center">
            
            <div class="py-4 px-4 mx-0 lg:mx-8" id="contact" >
                <div class="grid justify-content-between">
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                            <img
            src="images/eTicket_logo_new.png"
            class="w-12"
            alt="mockup mobile"
          />
                        </div>
                    </div>
                    <div class="col-12 md:col-6" style="margin-top:-1.5rem;">
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3">
                            <h4 style="color: #6b719b; font-size: 40px; font-weight: 700">Register</h4>
                           
                           
                        </div>
                        <div class="flex flex-wrap align-items-center justify-content-start md:justify-content-start md:mb-0 mb-3">
                            <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-12" style="margin-top: 10%;">
                                        <span class="p-float-label">
                                            <InputText id="name" type="text" v-model="name"
                                            />
                                            <label for="name">Name</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-12" style="margin-top: 3%;">
                                        <span class="p-float-label">
                                            <InputText id="email" type="text" v-model="email_id"
                                            />
                                            <label for="email">Email</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-12" style="margin-top: 3%;">
                                        <span class="p-float-label">
                                            <InputText  type="number" v-model="phone"
                                            />
                                            <label >Mobile No.</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-12" style="margin-top: 3%;">
                                        <span class="p-float-label">
                                            <InputText id="name" type="password" v-model="password"
                                            />
                                            
                                            <label for="name">Password</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-12" style="margin-top: 3%;">
                                        <span class="p-float-label">
                                            <InputText id="con_password" type="password" v-model="conf_password"
                                            />
                                            <label for="con_password">Confirm Password</label>
                                        </span>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3">
                            <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-12">
                                        <Button class="p-button-help btn_light_blue" @click="submit_ticket()" label="Register"/>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
   
            </div>
            
           
           
           
              
       
        </div>
        <ScrollTop />
    </div>
    </div>
</template>

<script>
import apis from "@/apis";
import axios from "axios";
export default {
    name: 'EticketRegister',

    data() {
        return {
            isLoadingModel:false,
            fullPage:false,
            name:"",
            email_id:"",
            password:"",
            conf_password:"",
            phone:"",
            client_id:"",
        };
    },

    mounted() {
        this.client_id=localStorage.getItem('client_id');
        this.client_id='65a4f82f6177e69880ece5d6';
    },

    methods: {
        async reset_form()
        {
           
            this.name="",
            this.email_id="";
            this.password="";
            this.conf_password="";
            this.phone="";
            this.conf_password="";
            
        },
        async submit_ticket()
        { 

            if (this.name=='') 
            {   
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Username",
                    life: 3000,
                });
                return false;
            }
            if (this.email_id=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Email ID",
                    life: 3000,
                });
                return false;
            }
            if (this.password=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Password",
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password=='') 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Confirm Password",
                    life: 3000,
                });
                return false;
            }
            if (this.conf_password!=this.password) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Confirm Password not match with Password",
                    life: 3000,
                });
                return false;
            }
            if (this.phone.length > 10 || this.phone.length < 10) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Valid Mobile Number",
                    life: 3000,
                });
                return false;
            }

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to Register?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {

                    

                    var payload=
                    {
                        "name"          : this.name,
                        "email_id"      : this.email_id,
                        "password"      : this.password,
                        "mobile_number" : this.phone,
                        "client_id"     : this.client_id
                        
                    }
                    this.isLoadingModel = true;
                    var promise = apis.reg_user(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            this.$toast.add({ severity: 'success', summary: 'Success', detail: response.data.message, life: 3000 });
                            this.reset_form();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        else
                        {
                            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        
        }
        
    },
};
</script>

<style scoped>
#card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
}

.link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none; 
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px)
{
.lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
}
}
</style>