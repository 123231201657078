<template>
  <div class="surface-0 overflow-hidden">
    <div
      class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static"
    >
      <router-link to="/" class="flex align-items-center">
        <img
          src="images/eTicket_logo_new.png"
          alt="Sakai Logo"
          height="50"
          class="mr-0 lg:mr-2"
        />
        <!-- <span class="text-900 font-medium text-2xl line-height-3 mr-8"
          >eTicket</span
        > -->
        <h4 style="color: #6b719b; font-size: 17px; font-weight: 800">eTicket</h4>
      </router-link>
      <a
        class="cursor-pointer block lg:hidden text-700 p-ripple"
        v-ripple
        v-styleclass="{
          selector: '@next',
          enterClass: 'hidden',
          leaveToClass: 'hidden',
          hideOnOutsideClick: true,
        }"
      >
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div
        class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2"
        style="top: 92%"
      >
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer"
        >
          <li>
            <a
              @click="smoothScroll_router('Home')"
              class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
              v-ripple
            >
              <i class="pi pi-home py-1"></i>&nbsp;<span>Home</span>
            </a>
          </li>
          <!-- <li>
            <a
              @click="smoothScroll_router('Public Tickets')"
              class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
              v-ripple
            >
              <i class="pi pi-ticket py-1"></i>&nbsp;<span>
                Public Tickets</span
              >
            </a>
          </li> -->
          <li>
            <a
              @click="smoothScroll_router('Submit Ticket')"
              class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
              v-ripple
            >
              <i class="pi pi-plus-circle py-1"></i>&nbsp;<span>
                Submit Ticket</span
              >
            </a>
          </li>
          <li>
            <a
              @click="smoothScroll_router('Search Ticket')"
              class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
              v-ripple
            >
              <i class="pi pi-search py-1"></i>&nbsp;<span>Search Ticket</span>
            </a>
          </li>
          <li>
            <a
              @click="smoothScroll_router('FAQs')"
              class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
              v-ripple
            >
              <i class="pi pi-question-circle py-1"></i>&nbsp;<span>FAQs</span>
            </a>
          </li>
        </ul>
        <div
          class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0"
        >
          <Button
            @click="smoothScroll_router('Login')"
            label="Login"
            class="p-button-rounded border-none ml-5  text-white line-height-2 "
            style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
          ></Button>
          <Button
            @click="smoothScroll_router('Register')"
            label="Register"
            class="p-button-rounded border-none ml-5 font-light text-white line-height-2 "
            style="
                    box-shadow: 0 2px 6px #ffc107;
                    border-color: #ffc107 !important;
                    background-color: #ffc107 !important;
                  "
          ></Button>
        </div>
      </div>
    </div>

    <div v-if="selectedTab == 'Home'">
      <Homecomponent></Homecomponent>
    </div>
    <div v-if="selectedTab == 'Public Tickets'">
      <PublicTickets></PublicTickets>
    </div>
    <div v-if="selectedTab == 'Submit Ticket'">
      <SubmitTicket @redirect_to_login = "RedirectToLogin"></SubmitTicket>
    </div>
    <div v-if="selectedTab == 'Search Ticket'">
      <SearchTicket></SearchTicket>
    </div>
    <div v-if="selectedTab == 'FAQs'">
      <FAQs></FAQs>
    </div>
    <div v-if="selectedTab == 'Login'">
      <Login></Login>
    </div>
    <div v-if="selectedTab == 'Register'">
      <Register></Register>
    </div>


    <div class="py-4 px-4 mx-0 lg:mx-8" id="contact" >
            <div class="grid justify-content-between">
                
                <div class="col-12 md:col-4" style="margin-top:-1.5rem;">
                    <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
                        <img src="images/eTicket_logo_new.png" alt="footer sections" width="50" height="50" class="mr-2">
                        <h4 style="color: #6b719b; font-size: 20px; font-weight: 800">eTicket</h4>
                    </div>
                </div>

                <div class="col-12 md:col-4">
                    <div class="grid text-center md:text-left">
                        <div class="col-12 md:col-12">
                            <h4 style="color: #6b719b; font-size: 20px; font-weight: 800">About Us</h4>
                            <a>Au cours des 10 dernières années d'expérience et de compétences dans diverses technologies, nous avons construit d'excellents produits évolutifs. demolesdome</a>
                            
                        </div>
                        
                       
                    </div>
                </div>
                <div class="col-12 md:col-4">
                    <div class="grid text-center md:text-left">
                        <div class="col-12 md:col-12">
                            <h4 style="color: #6b719b; font-size: 20px; font-weight: 800">Contact Us</h4>
                            <a>C-303, Atlanta Shopping Mall, Nr. Sudama Chowk, Mota Varachha, Surat - 394101, Gujarat, India.</a><br>
                            <a>Email: cerisetechsolutions.com</a><br>
                            <a>Phone: +91 99999999</a>
                            
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </div>
       
  </div>
</template>


<script>
import Homecomponent from "../components/Home_component";
import PublicTickets from "../components/Public_Tickets";
import SubmitTicket from "../components/Submit_Ticket";
import SearchTicket from "../components/Search_Ticket";
import FAQs from "../components/FAQs";
import Login from "../components/Login";
import Register from "../components/Register";
export default {
  data() {
    return {
      selectedTab: "Home",
    };
  },
  components: {
    Homecomponent,
    PublicTickets,
    SubmitTicket,
    SearchTicket,
    FAQs,
    Login,
    Register,
  },
  methods: {
    RedirectToLogin()
    {
      this.selectedTab='Login';
    },
    smoothScroll(id) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    smoothScroll_router(data) {
      this.selectedTab = data;
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style>
#hero {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    radial-gradient(77.36% 256.97% at 77.36% 57.52%, #eeefaf 0%, #c3e3fa 100%);
  height: 700px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  #hero {
    -webkit-clip-path: ellipse(150% 87% at 93% 13%);
    clip-path: ellipse(150% 87% at 93% 13%);
    height: 530px;
  }
}

@media screen and (min-width: 1300px) {
  #hero > img {
    position: absolute;
  }

  #hero > div > p {
    max-width: 450px;
  }
}

@media screen and (max-width: 1300px) {
  #hero {
    height: 600px;
  }

  #hero > img {
    position: static;
    transform: scale(1);
    margin-left: auto;
  }

  #hero > div {
    width: 100%;
  }

  #hero > div > p {
    width: 100%;
    max-width: 100%;
  }
}

/* @media screen and (min-width: 992px)
{ */
.lg\:mx-8 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
/* } */
</style>