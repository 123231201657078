<template>
  <div style="background-color: #F4F6F9;">
    <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
    <div
      class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center"
      style="background-color: #f4f6f9"
      v-if="row_data.length==0"
    >
     
      <div id="card1" class="card d-flex justify-content-center">
        <div class="py-4 px-4 mx-0 lg:mx-8" id="contact">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6" style="margin-top: -1.5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <img
                  src="images/eTicket_logo_new.png"
                  class="w-12"
                  alt="mockup mobile"
                />
              </div>
            </div>
            <div class="col-12 md:col-6" style="margin-top: 5rem">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3"
              >
                <h4 style="color: #6b719b; font-size: 40px; font-weight: 700">
                  Search Ticket
                </h4>
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-start md:justify-content-start md:mb-0 mb-3"
              >
                <div class="col-12">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-12" style="margin-top: 10%">
                      <span class="p-float-label">
                        <InputText id="name" type="text" v-model="name" />
                        <label for="name">Ticket No</label>
                      </span>
                    </div>
                    <!-- <div class="field col-12 md:col-12" style="margin-top: 3%">
                      <span class="p-float-label">
                        <InputText id="email" type="text" v-model="email_id" />
                        <label for="email">Email</label>
                      </span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-center md:mb-0 mb-3"
              >
                <div class="col-12">
                  <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4"></div>
                    <div class="field col-12 md:col-4">
                      <Button
                        class="p-button-help btn_light_blue"
                        @click="submit_ticket()"
                        label="SEARCH"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div>

    <div v-else>
        <div class="col-12 md:col-12">
                <h4 class="d-flex text-center" style="color: #6b719b; font-size: 40px; font-weight: 700;margin-top: 2%;">
                    Ticket Details
                </h4>
            </div>
        <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8 justify-content-center" style="background-color: #f4f6f9">
            <div class="col-12">
                <div class="p-fluid formgrid grid">
                   
                    <div class="field col-12 md:col-8">
                        <div class="card">
                            <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                    <div class="field col-12 md:col-10">
                                        <p style="color: #6b719b; font-size: 20px; font-weight: 800">{{ row_data[0].ticket_no }}</p>
                                    </div>
                                    <div class="field col-12 md:col-2">
                                      <Button
                                        label="Back"
                                        v-tooltip.bottom="'Back'"
                                        @click="Goback"
                                        size="small"
                                        style="box-shadow: 0 2px 6px rgba(0,176,176,.549);border-color: #00b0b0!important;background-color: #00b0b0!important;"
                                      />
                                    </div>
                                    <div class="field col-12 md:col-2">
                                        <img
                                            style="border-radius: 50%; width: 60px; height: 60px"
                                            v-if="row_data[0].customer_profile_image"
                                            :src="row_data[0].customer_profile_image"
                                            :alt="row_data[0].customer_profile_image"
                                        />
                                    <span v-else class="i-circle-small " :style="'background-color:'+getcolorcode(row_data[0].customer_name)">{{convert_name(row_data[0].customer_name)}}</span>
                                    </div>
                                    <div class="field col-12 md:col-10">
                                        <p style="color: #6b719b; font-size: 20px; font-weight: 600">{{ row_data[0].title }}</p>
                                        <p style="color: #6b719b; font-size: 15px; font-weight: 400">{{ D_M_Y_formatDate(row_data[0].created_at) }}</p>
                                       
                                        
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <p style="color: #6b719b; font-size: 20px; font-weight: 650">Description:</p>
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <p style="color: #6b719b;" v-html="row_data[0].description" class="m-0"></p>
                                    </div>
                                    <div class="field col-12 md:col-12" v-if="row_data[0].ticket_comments.length > 0">
                                        <Card
              :key="index"
              v-for="(value, index) in row_data[0].ticket_comments"
              id="card_custom"
              
            >
              <template #content>
           
                <div class="grid">
                    <div class=" col-12 md:col-2">
                    
                      <img
                        style="border-radius: 50%; width: 80px; height: 80px"
                        v-if="value.customer_profile"
                        :src="value.customer_profile"
                        :alt="value.customer_profile"
                      />
                     
                      <span v-else class="i-circle-small " :style="'background-color:'+getcolorcode(value.username)">{{convert_name(value.username)}}</span>
                    </div>
                    <div class="col-12 md:col-8 text-left md:text-left">
                      <div>
                        <span style="font-size: 20px; font-weight: 700">{{
                          value.username
                        }}</span>
                      </div>
                      <div style="margin-top: 5px">
                        <span style="font-size: 15px; font-weight: 500">
                          {{ D_M_Y_formatDate(value.created_at) }}
                        </span>
                      </div>
                      <div style="margin-top: 20px" v-if="value['show_editor']==false || !value['show_editor']">
                        <span v-html="value.comment" style="font-size: 15px; font-weight: 400">
                   
                        </span>
                      </div>
                      <div style="margin-top: 10px" v-if="value.attachments && value.attachments.length>0">
                        <div :key="index" v-for="(info, index) in value.attachments">
                          Attachment {{index+1}} : <a  style="cursor: pointer;" @click="show_attachment(info)">view</a>
                        </div>
                        
                      </div>
                    </div>



                  </div>
              
              </template>
            </Card>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 md:col-4">
                        <div class="card">
                            <div class="col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-12">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 800">
                    Ticket Details
                  </p>
                </div>
                
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Customer:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ row_data[0].customer_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Email:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ row_data[0].customer_email }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Category:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ row_data[0].ticket_category_name }}
                  </p>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Agents:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <!-- <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{
                      row_data[0].agent_details == ""
                        ? "N/A"
                        : row_data[0].agent_details[0].agent_name
                    }}
                  </p> -->
                  <AvatarGroup class="mb-2">
                                  <Avatar
                                    v-for="(info, index) in row_data[0].agent_details"
                                    :key="index"
                                    :image="info.agent_profile"
                                    size="small"
                                    shape="circle"
                                   
                                    v-tooltip.bottom="info.agent_name"
                                    style="cursor: pointer;"
                                    class="zoom_out"
                                  ></Avatar>
                                </AvatarGroup>
                </div>
                <div class="field col-12 md:col-4">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 400">
                    Created:
                  </p>
                </div>
                <div class="field col-12 md:col-8">
                  <p style="color: #6b719b; font-size: 15px; font-weight: 700">
                    {{ D_M_Y_formatDate(row_data[0].created_at) }}
                  </p>
                </div>
                
                
              </div>
            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

    
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import ColorService from "../service/ColorService";
export default {
  name: "EticketRegister",
  data() {
    return {
        ColorService:null,
        color_data:[],
        row_data:[],
      isLoadingModel: false,
      fullPage: false,
      name: "",
    //   email_id: "",
      password: "",
      conf_password: "",
      phone: "",
      client_id: "",
    };
  },
  mounted() {
    this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });
    this.client_id = localStorage.getItem("client_id");
    this.client_id = "65a4f82f6177e69880ece5d6";
    this.row_data=[];
  },
  created(){
    this.ColorService = new ColorService();
  },
  methods: {
    async Goback() {
      this.row_data=[];
    },
    getcolorcode(name)
      {
        var name=this.convert_name(name)
        var temp=name.substring(0, 1);
        for (let index = 0; index < this.color_data.length; index++) 
        {
         if (this.color_data[index].name==temp) 
         {
            return this.color_data[index].code;
         }
          
        }
      },
    convert_name(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
        
      },
    async show_attachment(url)
    {
      window.open(url);
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    async reset_form() {
      (this.name = "");
    },
    async submit_ticket() {
      if (this.name == "") {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Ticket No",
          life: 3000,
        });
        return false;
      }
    //   if (this.email_id == "") {
    //     this.$toast.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: "Please Enter Email ID",
    //       life: 3000,
    //     });
    //     return false;
    //   }
      var payload = {
        ticket_no: this.name,
        // email_id: this.email_id,
        client_id: this.client_id,
       };
        this.isLoadingModel = true;
        var promise = apis.getticketDetails(payload);
        promise.then((response) => {
        this.isLoadingModel = false;
        this.row_data = response.data.data;
        });
    },
  },
};
</script>
<style scoped>
#card1 {
  background-color: #fff;
  border-top: 3px solid #6b719b;
}
.link-style {
  color: #6b719b;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
}
.link-style:hover {
  text-decoration: underline; /* Add underline on hover */
}
@media screen and (min-width: 992px) {
  .lg\:px-8 {
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }
}

#card_custom{
  
  border-top: 3px solid #6b719b;
}
</style>